.heroSection > h1 {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 60px;
  /* identical to box height */
  margin-top: 60px;
  color: #ffffff;
}

.classCard {
  background: #ffffff;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.16);
  width: 375px;
  height: 155px;
  margin: 0px 10px 20px 10px;
  cursor: pointer;
  text-decoration: none;
}
.classCard > div > div > span > img {
  transition: all 0.2s ease-in-out;
}
.classCard:hover div > div > span > img {
  transform: translateX(10px);
  transition: all 0.2s ease-in-out;
}
.classInfo {
  padding: 15px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.classInfo > h1 {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 31px;

  color: #9387a7;
  max-width: 100px;
  text-align: left;
}

.classInfo > div > div > h2 {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  padding: 0;
  margin: 0;
  text-align: left;
  color: #000000;
}

.classInfo > div > div > h2 > span {
  font-size: 14px;
}

.classInfo > div > div > h3 {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  padding: 0;
  margin: 0;
  text-align: left;
  color: #000000;
}

.classCalendar {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.16);
  margin-bottom: 23px;
  transform: translateY(-80px);

  /* width: 375px; */
  width: 770px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  background-color: white;
}
.classMiniCard {
  background: #ffffff;

  cursor: pointer;
  text-decoration: none;
}

.classMiniCard > img {
  width: 260px;
  height: 339px;
  object-fit: revert;
}
.classMiniCard > div > div > span > img {
  transition: all 0.2s ease-in-out;
}
.classMiniCard:hover div > div > span > img {
  transform: translateX(50px);
  transition: all 0.2s ease-in-out;
}
.classMiniInfo {
  padding: 15px;
  /* justify-content: space-between; */
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}

.classMiniInfo > h1 {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 31px;

  color: #9387a7;
  max-width: 100px;
  text-align: left;
}

.classMiniInfo > div > div > h2 {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  padding: 0;
  margin: 0;
  text-align: left;
  color: #000000;
}

.classMiniInfo > div > div > h2 > span {
  font-size: 14px;
}

.classMiniInfo > div > div > h3 {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  padding: 0;
  margin: 0;
  text-align: left;
  color: #000000;
}

.instructorCard {
  background: #ffffff;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.16);
  transform: translateY(-80px);

  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 20px;
  /* cursor: pointer; */
  text-decoration: none;
  width: 375px;
  margin: auto;
  margin-top: 10px;
}

.classTime > p {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 50px;
  /* or 49% */
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;

  color: #9387a7;
  align-items: end;
}

.classTime > p > span {
  font-size: 22px;
  margin: 0;
  padding: 0;

  line-height: 40px;
  margin-left: 10px;
}

.classInstructor,
.classDay {
  margin: 0px;
}

.classInstructor > span:nth-child(1),
.classDay > span:nth-child(1) {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 700;
  font-size: 8px;

  display: flex;
  align-items: center;

  color: #a7a7a7;
}

.classInstructor > span:nth-child(2) {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;

  display: flex;
  align-items: center;

  color: #a7a7a7;
}

.form {
  display: flex;
  /* flex-direction: column; */
  background: #ffffff;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.16);
  padding: 20px;
  transform: translateY(-100px);
  margin: 10px;

  width: 749px;
  height: 417px;
}

.inputs {
  display: flex;
  flex-direction: column;
  padding-top: 18px;
}

.form input {
  width: 335px;
  height: 54px;
  /* margin: auto; */
  margin-bottom: 22px;
  border: 0.5px solid #000000;

  padding: 20px;
}

.form input::placeholder {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 33px;
  display: flex;
  align-items: center;

  color: rgba(147, 135, 167, 0.3);
}

.genderOptions {
  display: flex;
  justify-content: space-between;

  flex-direction: column;
  padding-top: 18px;
  margin-left: 20px;
  height: 300px;
}

.genderOptions > div {
  border: 0.5px solid #000000;
  padding: 25px 50px 15px 50px;
  /* width: 48%; */
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  width: 321px;
  height: 130px;
}
.genderOptions > div > img {
  height: 50px;
  object-fit: contain;
  margin-bottom: 10px;
}
.genderOptions > div > p {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
  color: #9387a7;
}

.reserveBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  cursor: pointer;
  margin-left: 20px;
}

.reserveBtn > p {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  /* or 109% */

  display: flex;
  align-items: center;

  color: #febf10;
}

.optionSelected {
  border: 3px solid #000000 !important;
}

.optionSelected > p {
  color: black !important;
}

.classesTimes {
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
}

.reserveLink {
  /* display: flex; */
  /* flex-direction: column; */
}
.reserveLink {
  text-decoration: none;
  margin-bottom: 20px;
}
.reserveLink > span {
  color: #febf10;
  font-size: x-large;
}

.reserveLink > img {
  transition: all 0.2s ease-in-out;
  margin-left: 20px;
}

.reserveLink:hover img {
  transform: translateX(50px);
  transition: all 0.2s ease-in-out;
}

@media only screen and (max-width: 800px) {
  .classCalendar {
    width: 375px;
    flex-direction: column;
  }
  .classMiniCard {
    width: 375px;

    height: 100px;
  }

  .classMiniCard > img {
    width: 115px;
    height: 84px;
    object-fit: revert;
  }

  .classMiniInfo {
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }
  .classesTimes {
    flex-direction: column;
  }
  .form {
    flex-direction: column;
    width: 375px;
    height: 531px;
  }
  .genderOptions {
    flex-direction: row;
    height: 135px;
    margin: auto;
    padding: 0px;
  }

  .genderOptions > div {
    width: 159px;
    height: 135px;
    flex-direction: column;
  }

  .reserveBtn {
    margin: auto;
    padding: 20px;
  }
}
